import React, { useState } from "react"
import SEO from "../components/seo"
import Img from "gatsby-image"
import classes from "./css/details.module.css"
import Dropdown from "react-dropdown"
import "../css/dropdown.css"

const ProductDetails = data => {
  console.log(
    data.data.contentfulProduct,
    data.data.contentfulProduct.slug !== "/certification-test"
  )
  const [quantity, setQuantity] = useState({
    value: 1,
    label: `1 ${
      data.data.contentfulProduct.slug !== "/certification-test"
        ? "set"
        : "Test"
    }`,
  })

  const updateQuantityHandler = e => {
    setQuantity(prevState => ({ ...prevState, value: e.value, label: e.label }))
    console.log(e)
  }

  const quantityItems =
    data.data.contentfulProduct.slug !== "/certification-test" ? 5 : 500

  const options = Array.from({ length: quantityItems }, (_, index) => ({
    value: index + 1,
    label: `${index + 1} ${
      data.data.contentfulProduct.slug !== "/certification-test"
        ? "set"
        : "Test"
    }`,
  }))

  return (
    <React.Fragment>
      <SEO
        title={data.data.contentfulProduct.name}
        keywords={[`gatsby`, `application`, `react`]}
      />
      <div className={classes.productPageRoot}>
        <div
          className={[
            "container",
            "details-page",
            classes.productContainer,
          ].join(" ")}
        >
          <div className={[classes.product, "product-details"].join(" ")}>
            <div className={classes.productImageContainer}>
              {data.data.contentfulProduct.image === null ? (
                <div className="no-image">No Image</div>
              ) : (
                <Img
                  fixed={data.data.contentfulProduct.image.fixed}
                  className={classes.productImage}
                />
              )}
            </div>
            <div>
              <div>
                <h2 className={classes.productName}>
                  {data.data.contentfulProduct.name}
                </h2>
              </div>
              {data.data.contentfulProduct.slug !== "/certification-test" && (
                <div
                  className={classes.displayText}
                  dangerouslySetInnerHTML={{
                    __html:
                      data.data.contentfulProduct.details.childMarkdownRemark
                        .html,
                  }}
                />
              )}
              <div>
                <ul>
                  <li className={classes.displayText}>
                    {data.data.contentfulProduct.slug !==
                      "/certification-test" &&
                      "Cards will be sent with documentation forms."}{" "}
                    If new or additional forms are needed, please download the
                    PDF on our "Certify My Herd" page or email us at
                    tenetbeef@gmail.com.
                  </li>
                  {data.data.contentfulProduct.slug !==
                    "/certification-test" && (
                    <li className={classes.displayText}>
                      Please email us at tenetbeef@gmail.com for orders
                      exceeding 600 cards.
                    </li>
                  )}
                </ul>
              </div>
              <div className={classes.priceContainer}>
                <span className="price">
                  Price:{" "}
                  {(data.data.contentfulProduct.price * quantity.value).toFixed(
                    2
                  )}
                </span>
              </div>
              <div className={[classes.buyRow, "buynowinner"].join(" ")}>
                <div className={classes.dropdownContainer}>
                  <Dropdown
                    options={options}
                    onChange={e => updateQuantityHandler(e)}
                    value={quantity.label}
                    className={classes.dropDown}
                  />
                </div>
                <div className={classes.buyButtonContainer}>
                  <a
                    href="#"
                    className="Product snipcart-add-item"
                    data-item-id={data.data.contentfulProduct.slug}
                    data-item-price={data.data.contentfulProduct.price}
                    data-item-quantity={quantity.value}
                    data-item-image={
                      data.data.contentfulProduct.image === null
                        ? ""
                        : data.data.contentfulProduct.image.fixed.src
                    }
                    data-item-name={data.data.contentfulProduct.name}
                    data-item-url={data.data.contentfulProduct.slug}
                  >
                    <i className="fas fa-tags" />
                    Buy Now
                  </a>
                </div>
              </div>
            </div>
          </div>
              <div className={classes.orderInformationContainer}>
                <p className={classes.displayText}>
                  By ordering TENET™ Certification Cards and/or a TENET™
                  Certification Test, Purchaser acknowledges and agrees that:
                </p>
                <ol className={classes.mt15}>
                  <li>
                    Any test results provided will characterize the tenderness
                    rating only for the specific animal tested, and will
                    indicate whether the tested animal meets the qualification
                    standard for TENET® brand products.
                  </li>
                  <li>
                    The TENET® trademark may not be used by Purchaser in the
                    production, labeling, advertising or sale of cattle, beef,
                    embryos or semen unless specifically licensed in writing, in
                    advance, by Callipyge Genetics, LLC.
                  </li>
                  <li>
                    Use of the TENET® trademark is restricted to licensed users,
                    and may be subject to the payment of additional fees.{" "}
                  </li>
                </ol>
                <p className={classes.displayText}>
                  For additional information regarding licensing, contact
                  Callipyge Genetics, LLC at [
                  <a
                    href="mailto:callipygegenomics@gmail.com"
                    className={classes.linkStyle}
                  >
                    callipygegenomics@gmail.com
                  </a>
                  ]. The TENET® trademark and related proprietary technology are
                  the sole and exclusive property of Callipyge Genetics, LLC.
                  All rights reserved.
                </p>
              </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProductDetails

export const query = graphql`
  query ProductDetailsQuery($slug: String!) {
    contentfulProduct(slug: { eq: $slug }) {
      id
      name
      slug
      image {
        fixed(height: 500) {
          width
          height
          src
          srcSet
        }
      }
      price
      details {
        childMarkdownRemark {
          html
        }
      }
      productMorePhotos {
        id
        fixed(width: 1120, height: 600) {
          src
        }
      }
      rating
    }
  }
`
